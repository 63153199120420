import type { Dropdown, InternalLink, RelativeLink } from '../../pages/api/types';
import Icon from '../generic/Icon';
import { onElementBlur } from '../utils/onElementBlur';
import preventDefault from '../utils/preventDefault';
import { trackHeaderLinkClick } from '../utils/trackLinkClick';
import useBooleanState from '../utils/useBooleanState';

export interface PrimaryNavigationTopProps {
    links: (InternalLink | RelativeLink | Dropdown)[];
}

export default function PrimaryNavigationTop({ links }: PrimaryNavigationTopProps) {
    return (
        <nav className="primary-navigation">
            <ul className="primary-navigation__top-nav-items">
                {links.map((p) => {
                    switch (p._type) {
                        case 'dropdown':
                            return <DropdownLink key={p._key} {...p} />;
                        case 'namedInternalLink':
                            return <InternalLink key={p._key} {...p} />;
                        case 'namedRelativeLink':
                            return <RelativeLink key={p._key} {...p} />;
                    }
                })}
            </ul>
        </nav>
    );
}

function InternalLink({ link, name }: InternalLink) {
    return (
        <li>
            <a href={link?.internalReference.path} onClick={trackHeaderLinkClick}>
                {name || link?.internalReference.title}
            </a>
        </li>
    );
}
function RelativeLink({ name, url }: RelativeLink) {
    return (
        <li>
            <a href={url} onClick={trackHeaderLinkClick}>
                {name}
            </a>
        </li>
    );
}

function DropdownLink({ _key, links, name }: Dropdown) {
    const [open, setOpen] = useBooleanState();

    return (
        <li onBlur={onElementBlur(setOpen.toFalse)}>
            <a href="#" onClick={preventDefault(setOpen.toggle, trackHeaderLinkClick)} aria-expanded={open} aria-controls={_key}>
                {name}
                <Icon className="icon-chevron-down" renderWithSpan />
            </a>
            <ul id={_key} className="primary-navigation__top-nav-item-children">
                {links.map((p) => {
                    switch (p._type) {
                        case 'namedInternalLink':
                            return <InternalLink key={p._key} {...p} />;
                        case 'namedRelativeLink':
                            return <RelativeLink key={p._key} {...p} />;
                    }
                })}
            </ul>
        </li>
    );
}
