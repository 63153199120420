import { useContext } from 'react';
import { accountURL } from '../config/SiteConfig';
import Icon from '../generic/Icon';
import { UserContext } from '../login/userContext';

export default function MyPageLink() {
    return (
        <a href={accountURL()} rel="nofollow" className="secondary-navigation__link min-side__link">
            <Icon className="icon-my-profile-header" renderWithSpan />
            <span className="secondary-navigation__link-name">
                <MyPageText />
            </span>
        </a>
    );
}

function MyPageText() {
    const { isLoggedIn, user } = useContext(UserContext);

    switch (isLoggedIn) {
        case undefined:
            return <>&nbsp;</>;
        case true:
            return (
                <>
                    {user.firstName.substring(0, 25)} <span className="sr-only"> sin side</span>
                </>
            );
        case false:
            return <>Min side</>;
    }
}
