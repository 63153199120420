import { wishlistURL } from '../config/SiteConfig';
import Icon from '../generic/Icon';

export function Favorites() {
    return (
        <a href={wishlistURL()} rel="nofollow" className="secondary-navigation__link favourites__link">
            <Icon className="icon-favorite" renderWithSpan />
            <span className="secondary-navigation__link-name">Mine lister</span>
        </a>
    );
}
