const baseURL = '/vmpws/v2/vmp';

export function productSearchURL(query: string) {
    // TODO: change to /products/search? here
    return `${baseURL}/search?${query}`;
}

export function contentSearchURL(categoryCode: string, query: string) {
    return `${baseURL}/search/content?categoryCode=${categoryCode}&${query}`;
}

export function tagSearchURL(tagCode: string, query: string) {
    return `${baseURL}/search/emner?tag=${tagCode}&${query}`;
}

export function userLocationURL() {
    return `${baseURL}/search/userlocation`;
}

export function productGuideURL(query: URLSearchParams) {
    return `${baseURL}/products/productGuide?${query}`;
}

export function autocompleteURL() {
    return `${baseURL}/products/suggestions`;
}
